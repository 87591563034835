<template>
    <v-chip small :color="color" outlined v-if="status">
        <v-icon class="mx-1" small v-if="status.icon"> {{ status.icon }}</v-icon>
        {{status.text}}
    </v-chip>
</template>
<script>
export default {
    props: {
        props:Object,
        field:Object
    },
    computed: {
        color () {
            switch (this.props.value) {
                case "confiremed":
                    return 'success';
                 case "denied":
                    return 'error';
                 case "maybe":
                    return 'warning';
                default:
                   return '';
            }
        },
        all () {
            const { $t: __} = this
            return [
                {
                    text: __('waiting_confirmation'),
                    value: 'pendding',
                    icon: 'mdi-account-clock'
                },
                {
                    text: __('confiremed'),
                    value: 'confiremed',
                    icon: 'mdi-account-check'
                },
                {
                    text: __('denied'),
                    value: 'denied',
                    icon: 'mdi-account-cancel'
                },
                {
                    text: __('maybe'),
                    value: 'maybe',
                    icon: 'mdi-account-question'
                }
            ]
        },
        status () {
            return this.all.find(e => e.value == this.props.value)
        }
    }
}
</script>